@charset "utf-8";
@import 'bulma';
@import '~bulma-steps';
@import '~bulma-slider';

html,
body {
  height: 99%;
  width: 100%;
}

.width-100 {
  width: 100%;
}

#root {
  user-select: none;
}

.head-space {
  width: 5%;
  margin: 0.5rem 0;
  white-space: nowrap;
}

#name-wrapper {
  float: left;
  width: 78%;
}

#age-wrapper {
  float: right;
  width: 20%;
}

.home-button {
  max-width: 300px;
  width: 80%;
}

.slider-scale {
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  margin-top: -1rem;
}

.time-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lift {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.circle {
  align-items: center;
  background-color: black;
  color: white;
  display: flex;
  font-size: 4rem;
  justify-content: center;
  user-select: none;
  border: 10px solid black;

  &.pressed {
    border-color: $success;
  }

  &.triggered {
    border-color: $danger;
  }
}

.workout-preview-spacer {
  height: 20rem;
}

.workout-timer {
  border-top: 2px solid $success;
  bottom: 0;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
}

.fixed-button {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.table {
  .headings {
    border-bottom: 1px solid black;
    font-weight: bold;
  }
  .clickable {
    cursor: pointer;
  }
}

@media (orientation: portrait) {
  .circle {
    border-radius: 25vw;
    height: 50vw;
    width: 50vw;
  }
}

@media (orientation: landscape) {
  .circle {
    border-radius: 25vh;
    height: 50vh;
    width: 50vh;
  }
}
